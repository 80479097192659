<template>
  <FinalForm
    ref="form"
    :submit="onSubmit"
  >
    <template v-slot="props">
      <form v-if="!loading" @submit="props.handleSubmit">
        <div class="form-row bg-gray-100 p-4 mb-6">
          <SelectInput
              :options="partnerOptions"
              name="partnerId"
              label="partner"
              placeholder="partner"
              :validate="required"
          />
          <div class="form-col">
            <div class="label">purposes</div>
            <div v-for="purpose in purposes" :key="purpose.uuid" class="relative flex items-center">
              <CheckboxInput
                :name="`purposes[${purpose.uuid}]`"
                :value="purpose.name"
                :inline="true"
                :disabled="isPurposeAssignedToPartner(purpose)"
                :validate="oneTrueRequired('purposes')"
              />
              <Popup>
                <template v-slot:control>
                  <icon
                    v-if="purpose.description"
                    name="questionMark"
                    class="icon-info ml-2"
                  />
                </template>
                <template v-slot:default>{{purpose.description}}</template>
              </Popup>
              <Popup>
                <template v-slot:control>
                  <icon
                    v-if="isPurposeAssignedToPartner(purpose)"
                    name="info"
                    class="icon-warning"
                  />
                </template>
                <template v-slot:default>
                  <div class="text-xs font-bold text-black">
                    Note: this purpose cannot be selected because it is currently assigned to a different partner.
                    <hr class="my-2 border-blue-400">
                    <div>current partner: {{getPurposePartnerName(purpose)}}</div>
                    <div class="btn-link" @click="unassignPurpose(purpose)">unassign purpose from partner</div>
                  </div>
                </template>
              </Popup>

            </div>
          </div>
        </div>
        <div class="grid grid-flow-row grid-cols-2 gap-4">
          <div v-for="field in fields" :key="field.field_name">
            <TextField
              :name="`fieldValues[${field.fieldName}]`"
              :label="field.fieldName"
              :hint="field.fieldDescription"
              :validate="required"
            />
          </div>
        </div>
        <ModalFooter :footer="footer" :secondary="goBack"/>
      </form>
    </template>
  </FinalForm>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import InitializeFormMixin from "@/components/form/InitializeFormMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import CheckboxInput from "@/components/form/CheckboxInput";
  import TextField from "@/components/form/TextField";
  import SelectInput from "@/components/form/SelectInput";
  import Icon from "@/components/ui/Icon";
  import Popup from "@/components/ui/Popup";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";

  export default {
    name: "CustomerIntegrationsForm",
    components: {
      ModalFooter,
      Popup,
      Icon,
      SelectInput,
      TextField,
      CheckboxInput,
      FinalForm,
    },
    mixins: [
      ValidatorMixin,
      InitializeFormMixin,
      NotifyMixin,
      ConfirmationMixin
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        partners: [],
        purposes: [],
        communityPurposes: [],
        fields: [],
        loading: true,
        footer: {
          primaryButton: 'save partner',
          secondaryButton: 'cancel',
        }
      }
    },
    computed: {
      partnerOptions() {
        const communityPartnersIds = this.communityPurposes?.map(({partner}) => partner.uuid);
        return  this.partners.filter(p => this.$route.name.includes('edit') || !communityPartnersIds.includes(p.uuid))
          .map(p => ({key: p.uuid, value: p.name}));
      },
      communityId() {
        return this.$route.params.communityId;
      }
    },
    methods: {
      fetchPartners() {
        return this.$partnerDataProvider.getList('partners')
          .then(({content}) => {
            this.partners = content;
          })
          .catch((error) => this.notifyError(error.message));
      },
      fetchCommunityPurposes() {
        return this.$partnerDataProvider.getList('communityPurposes', {communityId: this.communityId})
          .then((content) => {
            this.communityPurposes = content;
          })
          .catch((error) => this.notifyError(error.message));
      },
      isPurposeAssignedToPartner(purpose) {
        const communityPurpose = this.communityPurposes.find(p => p.purpose.uuid === purpose.uuid);
        return !!communityPurpose && communityPurpose.partner.uuid !== this.$refs.form.formState.values.partnerId;
      },
      getPurposePartnerName(purpose) {
        const communityPurpose = this.communityPurposes.find(p => p.purpose.uuid === purpose.uuid);
        return communityPurpose.partner.name;
      },
      unassignPurpose(purpose) {
        this.loading = true;
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to unassign this purpose from partner?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$partnerDataProvider.delete('communityPurposes', {communityId: this.communityId, id: purpose.uuid})
              .then(() => this.fetchCommunityPurposes())
              .catch((error) => this.notifyError(error.message))
              .finally(() => this.loading = false)
            : null
          );
      },
      goBack(e) {
        e.preventDefault();
        this.$router.replace({name: 'company.communities.integrations.index', communityId: this.communityId})
      }
    },
    mounted() {
      this.$watch(
        () => this.$refs.form.formState.values.partnerId,
        val => {
          const partner = this.partners.find(p => p.uuid === val);
          this.purposes = partner?.purposes;
          this.fields = partner?.fields;
        }
      );

      Promise.all([
        this.fetchPartners(),
        this.fetchCommunityPurposes()
      ])
        .finally(() => this.loading = false)
    },
  }
</script>

<style scoped>
  .icon-info {
    @apply text-gray-500 w-4 h-4 cursor-pointer;
  }

  /*.question-mark-circle {*/
  /*  @apply w-4 h-4*/
  /*}*/

  .icon-warning {
    @apply ml-2 w-4 h-4 cursor-pointer;
    color: #BE7777;
  }
</style>
