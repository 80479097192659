<template>
  <div class="popup-wrapper">
    <span @click.stop="handleClick">
      <slot name="control"></slot>
    </span>
    <div
      class="popup-content--wrapper absolute z-50 bg-blue-50 mt-2 px-6 py-4"
      v-if="visible"
    >
      <slot name="default"></slot>
      <div class="arrow-up"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Popup",
    data: () => {
      return {
        visible: false
      }
    },
    methods: {
      handleClick() {
        this.visible = !this.visible;
      },
      handleOutsideClickEvent(e) {
        if(this.visible) {
          const el = this.$el;

          if( el && !el.contains(e.target)) {
            this.visible = false;
          }
        }
      },
    },
    created() {
      window.addEventListener('click', this.handleOutsideClickEvent);
    }
  }
</script>

<style scoped>
  .popup-wrapper {
    @apply relative;
  }

  .popup-content--wrapper{
    @apply border border-solid border-blue-400;
    min-width: 200px;
  }

  .arrow-up{
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: .5rem;
    filter: drop-shadow(0px -1px 0px #A9C4D6);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #F7FCFF;
  }
</style>
